<template>
  <div class="filter">
    <div class="filter__header header--fix">
      <div class="page-header">
        <div class="container">
          <div class="page-header__wrapper">
            <button
              class="page-header__back js-filter-close"
              @click.prevent="$emit('close')"
            >
              <svg class="icon icon-arrow-left ">
                <use xlink:href="#arrow-left"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter__title">
      <div class="container">
        <div class="title">Фильтр по параметрам</div>
      </div>
    </div>
    <div class="filter-options">
      <div class="container">
        <div
          class="filter-option"
          v-for="(option, index) in filter"
          :key="index"
        >
          <Accordeon
            v-if="option.type === 'checkbox'"
            :classItem="'accordeon--tr'"
            :item="option"
          >
            <div
              class="accordeon-body__item"
              v-for="item in option.items"
              :key="item.id"
            >
              <label class="checkbox">
                <input
                  type="checkbox"
                  :value="item"
                  :checked="item.checked"
                  :disabled="item.disabled"
                  v-model="checkedItem"
                />
                <span class="checkbox__indicator">
                  <svg class="icon icon-check">
                    <use xlink:href="#check"></use>
                  </svg>
                </span>
                <span class="checkbox__description">
                  {{ item.name }}
                </span>
              </label>
              <div class="accordeon-body__item-count"></div>
            </div>
          </Accordeon>

          <Range v-else :option="option" v-model="price" />
        </div>
      </div>
    </div>
    <div class="filter-buttons">
      <div class="container">
        <div class="filter-buttons__wrapper">
          <div class="filter-buttons__item">
            <button class="btn btn--grey">Сбросить</button>
          </div>
          <div class="filter-buttons__item">
            <button class="btn" @click.prevent="filtration()">Показать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordeon from "@/components/cards/accordeon";
import Range from "@/components/filter/range";

export default {
  components: {
    Accordeon,
    Range,
  },

  data() {
    return {
      price: [],
      checkedItem: [],
    };
  },

  computed: {
    filter() {
      return this.$store.state.catalog.filter;
    },

    sort() {
      return this.$store.state.catalog.sort;
    },
  },

  methods: {
    filtration() {
      this.$store
        .dispatch("CATALOG_FILTRATION", {
          'category': this.$route.params.category,
          'page': this.$route.params.page ?? 1,
          'filter': [
            ...this.price,
            ...this.checkedItem,
          ],
        });

      this.$emit("close");
    },
  },
};
</script>
