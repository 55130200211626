<template>
  <div>
    <div class="filter-option__title">{{ option.name }}</div>
    <div class="filter-option__price">
      <input class="input" :value="minValue" @change="setMinValue()" />
      <div class="filter-option__separator">-</div>
      <input class="input" :value="maxValue" @change="setMaxValue()" />
    </div>
    <div ref="slider"></div>
  </div>
</template>

<script>
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      minValue: this.option.min.value,
      maxValue: this.option.max.value,
    };
  },

  computed: {
    value() {
      const list = [];
      list.push({ id: this.option.max.id, value: this.maxValue });
      list.push({ id: this.option.min.id, value: this.minValue });
      return list;
    },
  },

  watch: {
    minValue() {
      this.$emit("update:modelValue", this.value);
    },
    maxValue() {
      this.$emit("update:modelValue", this.value);
    },
  },

  methods: {
    setMinValue() {
      this.$refs.slider.noUiSlider.set([event.target.value, null]);
    },
    setMaxValue() {
      this.$refs.slider.noUiSlider.set([null, event.target.value]);
    },
  },

  mounted() {
    const slider = this.$refs.slider;

    const min = this.option.min.value;
    const max = this.option.max.value;

    noUiSlider.create(slider, {
      start: [min, max],
      connect: true,
      range: {
        min: min,
        max: max,
      },
    });

    slider.noUiSlider.on("update", (values) => {
      this.minValue = values[0];
      this.maxValue = values[1];
    });
  },
};
</script>

<style lang="sass">
.noUi-horizontal
  height: 5px
  background: #BDBDBD
  border-radius: 10px
  border: transparent

.noUi-connect
  background: #FF980C

.noUi-handle
  height: 20px !important
  width: 20px !important
  border-radius: 10px
  border: transparent
  background: #FF980C
  box-shadow: none
  right: -10px !important

  &::after,
  &::before
    content: none
</style>
