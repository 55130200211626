<template>
  <div class="accordeon" :class="classItem + ' ' + isClassActive">
    <div :class="isVacansies ? '' : 'container'">
      <button class="accordeon-head" @click.prevent="isShowBody()">
        <div class="accordeon-head__description">
          <template v-if="classItem === 'accordeon--vacancy'">
            <div class="accordeon-head__description-vacancy">
              {{ item.name }}
            </div>
            <div class="accordeon-head__description-salary">
              {{ item.salary }}
            </div>
          </template>

          <template v-else>{{ item.name }}</template>
        </div>
        <div class="accordeon-head__icon">
          <svg class="icon icon-down ">
            <use xlink:href="#down"></use>
          </svg>
        </div>
      </button>
      <div class="accordeon-body" :ref="'accordeon-body-' + item.id">
        <div class="accordeon-body__text">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    classItem: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    isClassActive() {
      return this.isOpen ? "accordeon--active" : "";
    },
    isVacansies() {
      return this.classItem === "accordeon--vacancy";
    },
  },

  methods: {
    isShowBody() {
      this.isOpen = !this.isOpen;

      const panel = this.$refs[`accordeon-body-${this.item.id}`];
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    },
  },
};
</script>
